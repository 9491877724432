<template>
    <div class="light-shelves">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Estantería Liviana',
		meta: [
			// Ummami Express
			{ name: 'description', content:'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			{ name: 'keywords', content:'estantería liviana, estantería metálica liviana, rack de almacenamiento, carga selectiva, rack selectivo, montacargas, estanterías para palletsestanteria metalica, estanterias metalicas, estanteria metalica bucaramanga, estanteria metalica barranquilla, estanteria pesada, estanteria liviana, fabrica de estanterias metalicas, racks de almacenamiento, estanterias metalicas en bucaramanga, estanterias metalicas en barranquilla' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Estantería Liviana'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Estantería Liviana'},
			{name: 'twitter:description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Estantería Liviana'},
			{itemprop: 'description', content: 'fabricantes de estantería metálica para sistemas de almacenamiento en la ciudad de bucaramanga, barranquilla, valledupar, buenaventura, cucuta' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Estanterías livianas',
                types: [
                    { text: 'Todos', value: 'todos' },
                ],
                products: [
                    {
                        name: 'Estatería liviana',
                        description: `
                            <p>
                                Cuentan con la función de aprovechar el
                                espacio al máximo y al mismo tiempo crear un ambiente agradable con
                                cada producto. Por lo tanto, son ideales para supermercados, almacenes
                                comerciales, oficinas, bibliotecas donde las estructuras estén al alcance del
                                público.
                            </p> 
                            <p>
                                Se compone de vigas y columnas que se enganchan en un sistema de
                                tornillos a través de planos inclinados generando rigidez y estabilidad. Su
                                altura se permite regular y adaptar a cualquier producto.
                            </p>
                        `,
                        url_image: 'estanterias-metalicas/liviana/liviana_4.jpg',
                        slides: [
                            'estanterias-metalicas/liviana/liviana_2.jpg',
                            'estanterias-metalicas/liviana/liviana_3.jpg',
                            'estanterias-metalicas/liviana/liviana_4.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ]
            }
        }
    }
}
</script>