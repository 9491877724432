import { render, staticRenderFns } from "./LightShelves.vue?vue&type=template&id=26034560&"
import script from "./LightShelves.vue?vue&type=script&lang=js&"
export * from "./LightShelves.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports